import { Flex, Heading, Image, Text } from '@chakra-ui/react';

type EmptyStateProps = {
  text: string;
  boxSize?: string;
  description?: string;
  dataTestId: string;
};

const EmptyState = ({
  text,
  boxSize = '24',
  description = '',
  dataTestId,
}: EmptyStateProps) => (
  <Flex
    flexDir='column'
    justifyContent='center'
    alignItems='center'
    m='auto'
    data-testid={dataTestId}
  >
    <Image
      boxSize={boxSize}
      src='https://sitecorecontenthub.stylelabs.cloud/api/public/content/5d2c4616a0304589818f265edc4471ce'
      alt='cactus'
    />
    <Flex alignItems='center' direction='column' gap={1}>
      <Heading size='md'>{text}</Heading>
      {description && <Text variant='subtle'>{description}</Text>}
    </Flex>
  </Flex>
);

export default EmptyState;
