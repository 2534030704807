import { useEffect } from 'react';
import {
  Flex,
  Heading,
  IconButton,
  useClipboard,
  useToast,
} from '@chakra-ui/react';
import { mdiContentCopy } from '@mdi/js';
import { PortalIcon } from 'features/common/components/PortalIcon';

export const ContextNameSection = ({ name }: { name: string }) => {
  const toast = useToast();
  const { onCopy, hasCopied } = useClipboard(name);

  useEffect(() => {
    if (hasCopied) {
      toast({
        status: 'success',
        description: 'Copied',
        duration: 2000,
      });
    }
  }, [hasCopied, toast]);
  return (
    <Flex
      flexDir='column'
      gap={1}
      data-testid='app-details-header-app-name-container'
      justifyContent='center'
    >
      <Flex gap={2} alignItems='center'>
        <Heading as='h1' data-testid='app-details-header-app-name'>
          {name}
        </Heading>
        <IconButton
          size='sm'
          variant='ghost'
          onClick={onCopy}
          icon={<PortalIcon path={mdiContentCopy} />}
          aria-label='Copy url'
          data-testid='app-details-header-copy-url-icon'
        />
      </Flex>
    </Flex>
  );
};
