import { useMemo } from 'react';
import { useGetFilteredContexts } from '../api/useGetFilteredContexts';
import {
  FilteredContext,
  SitecoreContextResourceType,
  FilteredContextWithResources,
} from '../types/types';

type useFilteredContextswithResourcesProps = {
  resources: SitecoreContextResourceType[];
  sitecoreContextId: string;
};

export const useFilteredContextswithResources = ({
  resources = [],
  sitecoreContextId,
}: useFilteredContextswithResourcesProps): {
  filteredContextsWithResources: FilteredContextWithResources[];
  isLoading: boolean;
} => {
  const { data: filteredContextsData, isLoading: filteredContextsLoading } =
    useGetFilteredContexts(sitecoreContextId);

  const filteredContexts = useMemo(
    () => (filteredContextsData?.data.length ? filteredContextsData?.data : []),
    [filteredContextsData?.data],
  );

  const resourcesMap = useMemo(
    () => new Map(resources.map((resource) => [resource.id, resource])),
    [resources],
  );

  //@ts-ignore
  const filteredContextsWithResources: FilteredContextWithResources[] = useMemo(
    () =>
      filteredContexts.map(
        ({ allowedResourceIds, ...rest }: FilteredContext) => {
          const resources = allowedResourceIds
            .map((resourceId) => resourcesMap.get(resourceId))
            .filter(Boolean);

          return {
            ...rest,
            resources,
          };
        },
      ),
    [filteredContexts, resourcesMap],
  );

  return useMemo(
    () => ({
      filteredContextsWithResources,
      isLoading: filteredContextsLoading,
    }),
    [filteredContextsWithResources, filteredContextsLoading],
  );
};
