import { ToastProps, useToast } from '@chakra-ui/react';
import { AxiosInstance } from 'axios';
import {
  MutationFunction,
  QueryFunction,
  QueryFunctionContext,
  QueryKey,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueries,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useAuthenticatedAxios } from './AxiosProvider';
import { errorsMap } from './errorsMap';

export const errorCallback = (
  err: any,
  scope: string | undefined,
  toast?: (props: ToastProps) => void,
) => {
  const statusCode = err.response ? err.response.status : null;
  const error = errorsMap.find(
    (errorMap) => errorMap.scope === scope && errorMap.code === statusCode,
  );

  // this error will be caught by the error boundary and will display the Error Page
  if (error?.throwError) {
    return err;
  }

  if (error && toast) {
    toast({
      status: 'error',
      title: `Error ${error.code}`,
      description: error.message,
    });
  }
};

const errorBoundaryHandler = (err: any, scope: string | undefined) => {
  const statusCode = err.response ? err.response.status : null;
  const error = errorsMap.find(
    (errorMap) => errorMap.scope === scope && errorMap.code === statusCode,
  );

  return Boolean(error?.throwError);
};

export const useAuthQueries = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queries: Array<{
    key: TQueryKey;
    fetcher: (
      axiosInstanceWithAuth: AxiosInstance,
      queryFnContext?: QueryFunctionContext<QueryKey, TQueryKey>,
    ) => TQueryFnData | Promise<TQueryFnData>;
    options?: Omit<
      UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
      'queryKey' | 'queryFn'
    >;
    scope?: string;
  }>,
): UseQueryResult<TData, TError>[] => {
  const toast = useToast();
  const axiosContext = useAuthenticatedAxios();

  if (!axiosContext)
    throw new Error(
      'useAuthQueries cannot be used outside of auth and axios context',
    );

  const enhancedQueries = queries.map(({ key, fetcher, options, scope }) => {
    const enhancedFetcher: QueryFunction<TQueryFnData, TQueryKey> = (params) =>
      fetcher(axiosContext, params);
    options = options || {};

    const defaultErrorCallback = options.onError;

    options.onError = (err) => {
      if (defaultErrorCallback) {
        return defaultErrorCallback(err);
      }
      return errorCallback(err, scope, toast);
    };

    return {
      queryKey: key,
      queryFn: enhancedFetcher,
      ...options,
      useErrorBoundary: (err: TError) => errorBoundaryHandler(err, scope),
    };
  });

  //@ts-ignore
  return useQueries(enhancedQueries);
};

// Needs to be used inside Auth0Context and AxiosProvider
export const useAuthQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  key: TQueryKey,
  fetcher: (
    axiosInstanceWithAuth: AxiosInstance,
    queryFnContext?: QueryFunctionContext<QueryKey, TQueryKey>,
  ) => TQueryFnData | Promise<TQueryFnData>,
  options?: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    'queryKey' | 'queryFn'
  >,
  scope?: string,
): UseQueryResult<TData, TError> => {
  const toast = useToast();
  const axiosContext = useAuthenticatedAxios();

  if (!axiosContext)
    throw new Error(
      'useAuthQuery can not be used outside of auth and axios context',
    );

  const enhancedFetcher: QueryFunction<TQueryFnData, TQueryKey> = (params) =>
    fetcher(axiosContext, params);
  options = options || {};

  const defaultErrorCallback = options.onError;

  options.onError = (err) => {
    if (defaultErrorCallback) {
      return defaultErrorCallback(err);
    }
    return errorCallback(err, scope, toast);
  };

  return useQuery<TQueryFnData, TError, TData, TQueryKey>(
    key,
    enhancedFetcher,
    {
      ...options,
      useErrorBoundary: (err) => errorBoundaryHandler(err, scope),
    },
  );
};
// Needs to be used inside Auth0Context and AxiosProvider
export const useAuthMutation = <
  TData = unknown,
  TError = unknown,
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  TVariables = void,
  TContext = unknown,
>(
  mutationFn: (
    axiosInstanceWithAuth: AxiosInstance,
  ) => MutationFunction<TData, TVariables>,
  options?: Omit<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    'mutationFn'
  >,
  scope?: string,
): UseMutationResult<TData, TError, TVariables, TContext> => {
  const toast = useToast();
  const axiosContext = useAuthenticatedAxios();

  if (!axiosContext)
    throw new Error(
      'useAuthMutation can not be used outside of auth and axios context',
    );

  options = options || {};

  const defaultErrorCallback = options.onError;

  options.onError = (err, variables, context) => {
    if (defaultErrorCallback) {
      return defaultErrorCallback(err, variables, context);
    }
    return errorCallback(err, scope, toast);
  };

  const enhancedMutationFn: MutationFunction<TData, TVariables> =
    mutationFn(axiosContext);

  return useMutation<TData, TError, TVariables, TContext>(enhancedMutationFn, {
    ...options,
    useErrorBoundary: (err) => errorBoundaryHandler(err, scope),
  });
};
