import { useFormik } from 'formik';
import { object, string } from 'yup';
import { useToast } from '@chakra-ui/react';
import { useCreateContextId } from '../api/useCreateContextId';
import { useHandleEditContextId } from './useHandleEditContextId';

const initialValues = {
  sitecoreContextId: '',
  name: '',
  description: '',
};

export type AddEditContextIdValues = typeof initialValues;

export const useAddEditContextId = ({
  onCloseDrawer,
  isInEditMode,
}: {
  onCloseDrawer: (sitecoreContextId?: string) => void;
  isInEditMode: boolean;
}) => {
  const toast = useToast();

  const {
    mutate: createSitecoreContext,
    isLoading: isCreateSitecoreContextLoading,
  } = useCreateContextId();

  const validationSchema = addEditContextIdValidationSchema();

  const {
    errors,
    values,
    handleChange,
    handleSubmit: formikHandleSubmit,
    setFieldValue,
    setValues,
    isValid,
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    validateOnBlur: false,
    onSubmit: async (values) => {
      onSubmit(values);
    },
  });

  const {
    editSitecoreContextId,
    isLoading: isEditContexIdLoading,
    updatedValues,
    isDisabledInEditMode,
  } = useHandleEditContextId({
    setValues,
    isInEditMode,
    values,
  });

  function onSubmit(values: any) {
    if (isInEditMode) {
      editSitecoreContextId(updatedValues, {
        onSuccess: () => {
          toast({
            status: 'success',
            description: 'Context ID edited successfully',
          });
          onCloseDrawer();
        },
        onError: (err) => {
          toast({
            status: 'error',
            description:
              //@ts-ignore
              err?.response?.data?.detail || 'Failed to edit context id',
          });
        },
      });
      return;
    }

    const createPayload = {
      name: values.name,
      description: values.description,
    };

    createSitecoreContext(createPayload, {
      onSuccess: (res) => {
        const sitecoreContextId = res?.data?.sitecoreContextId || '';
        toast({
          status: 'success',
          description: 'Context ID created successfully',
        });
        onCloseDrawer(sitecoreContextId);
      },
      onError: (err) => {
        toast({
          status: 'error',
          description:
            //@ts-ignore
            err?.response?.data?.detail || 'Failed to create context id',
        });
      },
    });
  }

  const isLoading = isCreateSitecoreContextLoading || isEditContexIdLoading;

  return {
    errors,
    values,
    handleChange,
    handleSubmit: formikHandleSubmit,
    setFieldValue,
    isValid,
    isLoading,
    isInEditMode,
    isDisabledInEditMode,
  };
};

const addEditContextIdValidationSchema = () =>
  object({
    sitecoreContextId: string().optional(),
    name: string().optional().max(80, 'Name must be less than 80 characters'),
    description: string()
      .optional()
      .max(160, 'Description must be less than 160 characters'),
  });
