import { Flex, Text } from '@chakra-ui/react';
import { ImageWithSkeleton } from 'features/common/components/ImageWithSkeleton';
import { SitecoreContextResourceType } from 'features/context-ids/types/types';
import { validResources } from 'features/context-ids/utils';

export const generateResourcesTableData = (
  resources?: SitecoreContextResourceType[],
) =>
  resources
    //@ts-ignore
    ?.map(({ name, tenantDisplayName }) => ({
      name,
      tenantDisplayName,
    })) ?? [];

const renderAppCell = ({ name }: Partial<SitecoreContextResourceType>) => {
  //@ts-ignore
  const { iconUrl, name: displayName } =
    validResources[name as keyof typeof validResources] ?? {};
  return (
    <Flex gap={2} alignItems='center'>
      <ImageWithSkeleton
        key={displayName}
        width='7'
        height='7'
        src={iconUrl}
        alt={displayName}
        flexShrink={0}
      />
      <Text>{displayName}</Text>
    </Flex>
  );
};

export const columns = [
  {
    id: 'name',
    Header: 'Resources',
    accessor: renderAppCell,
    disableSortBy: true,
  },
  {
    id: 'resource',
    Header: 'Tenant',
    accessor: 'tenantDisplayName',
    disableSortBy: true,
  },
];
