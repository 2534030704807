import { iconByProduct } from 'features/subscriptions/utils';
import { forIn, get, pick } from 'lodash';

export const PRODUCT_CODES = {
  XM_CLOUD: 'xmcloud',
  EDGE: 'edge',
  CDP: 'cdp',
  PERSONALIZE: 'personalize',
  PERSONALIZE_EMBEDDED: 'personalizeembedded',
  SEARCH: 'search',
};

export const CUSTOMER_ENV_TYPES = {
  PROD: 'prod',
  NON_PROD: 'nonprod',
};

export const EDGE_ENDPOINT_TYPES = {
  LIVE: 'Live',
  PREVIEW: 'Preview',
};

export const validResources = {
  'Sitecore Forms': {
    name: 'XMC Forms',
    iconUrl: iconByProduct.xmcloud.iconUrl,
  },
  'Sitecore Edge': {
    name: 'XMC Edge',
    iconUrl: iconByProduct.xmcloud.iconUrl,
  },
  'Sitecore Personalize': {
    name: 'Personalize',
    iconUrl: iconByProduct.personalize.iconUrl,
  },
  'Sitecore Search': {
    name: 'Search',
    iconUrl: iconByProduct.search.iconUrl,
  },
};

export function pickObjectsDifferences<T>(obj: T, comparator: T): any {
  const keysToPick: string[] = [];
  forIn(obj, (value, key) => {
    if (value !== get(comparator, key)) {
      keysToPick.push(key);
    }
  });

  return keysToPick.length ? pick(obj, keysToPick) : null;
}

const CHARSET =
  '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

export function encode(int: number) {
  if (int === 0) {
    return CHARSET[0];
  }

  let res = '';
  while (int > 0) {
    res = CHARSET[int % 62] + res;
    int = Math.floor(int / 62);
  }
  return res;
}

export function guidToBase62(guid: string): string {
  const hex = guid.replace(/-/g, '');
  const decimal = BigInt('0x' + hex);
  return encode(Number(decimal));
}

export const generateContextId = (guid: string) => guidToBase62(guid);
