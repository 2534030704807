import { Button, Flex, Heading } from '@chakra-ui/react';
import { mdiPlus } from '@mdi/js';
import EmptyState from 'features/common/components/EmptyState';
import { PortalIcon } from 'features/common/components/PortalIcon';
import { history } from 'features/common/history/history';
import { useFilteredContextswithResources } from 'features/context-ids/hooks';
import { getAddScopedContextRoute } from 'features/context-ids/routes';
import { useMemo } from 'react';
import { useParams } from 'react-router';
import { DataTable } from '../../../../../common/components/DataTable';
import { SitecoreContextResourceType } from '../../../../types/types';
import { getTableOptions } from './helpers';

// eslint-disable-next-line no-empty-function
const onChange = () => {};
const onClickAdd = (sitecoreContextId: string) =>
  history.push({
    pathname: getAddScopedContextRoute(sitecoreContextId),
    search: history.location.search,
  });

export const FilteredContextsTable = ({
  resources = [],
}: {
  resources?: SitecoreContextResourceType[];
}) => {
  const { sitecoreContextId } = useParams<{ sitecoreContextId: string }>();
  const { filteredContextsWithResources, isLoading } =
    useFilteredContextswithResources({ resources, sitecoreContextId });
  const options = useMemo(() => getTableOptions(), []);

  return (
    <Flex direction='column' gap={6}>
      <Flex justifyContent='space-between'>
        <Heading size='md' data-testid='scoped-context-id-header'>
          Scoped Context IDs
        </Heading>
        <Button
          variant='outline'
          onClick={() => onClickAdd(sitecoreContextId)}
          leftIcon={<PortalIcon path={mdiPlus} />}
          data-testid='create-scoped-context-id'
          data-behavior-analytics-id='Add scoped context ID button - Context IDs page'
        >
          Add scoped context
        </Button>
      </Flex>
      {filteredContextsWithResources.length || isLoading ? (
        <DataTable
          columns={options.columns}
          rowActions={options.rowActions}
          onChange={onChange}
          isLoading={isLoading}
          data={filteredContextsWithResources}
          pagination='hidden'
          data-behavior-analytics-feature='scoped context ids table'
        />
      ) : (
        <Flex my={5}>
          <EmptyState
            text='No sites resources'
            description='Create you first resource'
            boxSize='20'
            dataTestId='no-resource-container'
          />
        </Flex>
      )}
    </Flex>
  );
};
