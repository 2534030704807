import { UseMutationOptions, useQueryClient } from 'react-query';
import { useAuthMutation } from '../../common/apiUtils/queryHelper';
import { contextIdConfig } from '../config';

const {
  delete_scoped_context: { url, scope },
  get_scoped_contexts,
} = contextIdConfig;

export const useDeleteScopedContext = ({
  sitecoreContextId,
  scopeId,
  mutationOptions,
}: {
  sitecoreContextId: string;
  scopeId: string;
  mutationOptions: Omit<UseMutationOptions, 'mutationFn'>;
}) => {
  const queryClient = useQueryClient();
  return useAuthMutation(
    (axiosInstance) => () =>
      axiosInstance?.delete(url(sitecoreContextId, scopeId)),
    {
      ...mutationOptions,
      onSuccess: () => {
        //@ts-ignore
        mutationOptions.onSuccess?.();
        queryClient.invalidateQueries(
          get_scoped_contexts.queryKey(sitecoreContextId),
        );
      },
    },
    scope,
  );
};
