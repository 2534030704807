import { contextIdConfig } from '../config';
import { useAuthMutation } from '../../common/apiUtils/queryHelper';
import { TGenerateContextIdPayload } from '../types/types';
import { useQueryClient } from 'react-query';

const {
  edit_contextid: { url, scope },
  get_contextid,
} = contextIdConfig;

export const useEditContextId = ({
  sitecoreContextId,
}: {
  sitecoreContextId: string;
}) => {
  const queryClient = useQueryClient();

  return useAuthMutation(
    (axiosInstance) => (payload: TGenerateContextIdPayload) =>
      axiosInstance?.patch(url(sitecoreContextId), payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          get_contextid.queryKey(sitecoreContextId),
        );
      },
    },
    scope,
  );
};
