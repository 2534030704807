import { Route } from 'react-router-dom';
import { ContextIdDetails } from './features/context-id-details/ContextIdDetails';
import DeleteContextIdModal from './features/context-ids-home/components/DeleteContextIdModal';
import { ContextIds } from './features/context-ids-home/ContextIds';
import {
  contextIdsAddRoute,
  contextIdsRoute,
  getContextIdsDeleteRoute,
  getContextIdDetailsDeleteRoute,
  getContextIdsDetailsRoute,
  getContextIdsEditRoute,
  getAddScopedContextRoute,
  getEditScopedContextRoute,
  getDeleteScopedContextRoute,
} from './routes';
import { CreateEditContextIdDrawer } from './features/context-ids-home/components/CreateEditContextId/Main';
import { AddEditScopedContextDrawer } from './features/scoped-context/components/AddEditScopedContext';
import { DeleteScopedContextModal } from './features/scoped-context/components/DeleteScopedContextModal';

const ContextIdsContainer: React.FC = () => (
  <ContextIdsRoute>
    <ContextIdDetailsRoute>
      <Route
        path={getContextIdsEditRoute()}
        component={CreateEditContextIdDrawer}
      />
      <Route
        path={getContextIdDetailsDeleteRoute()}
        component={DeleteContextIdModal}
      />
      <Route
        path={getAddScopedContextRoute()}
        component={AddEditScopedContextDrawer}
      />
      <Route
        path={getEditScopedContextRoute()}
        component={AddEditScopedContextDrawer}
      />
      <Route
        path={getDeleteScopedContextRoute()}
        component={DeleteScopedContextModal}
      />
    </ContextIdDetailsRoute>
  </ContextIdsRoute>
);

export default ContextIdsContainer;

const ContextIdsRoute = ({ children }: { children?: React.ReactNode }) => (
  <Route
    path={contextIdsRoute}
    render={({ location }) =>
      location.pathname.includes('/details') ? (
        <>{children}</>
      ) : (
        <>
          <ContextIds />
          <Route
            exact
            path={contextIdsAddRoute}
            component={CreateEditContextIdDrawer}
          />
          <Route
            exact
            path={getContextIdsDeleteRoute()}
            component={DeleteContextIdModal}
          />
        </>
      )
    }
  ></Route>
);

const ContextIdDetailsRoute = ({
  children,
}: {
  children?: React.ReactNode;
}) => (
  <Route
    path={getContextIdsDetailsRoute()}
    render={() => (
      <>
        <ContextIdDetails />
        {children}
      </>
    )}
  ></Route>
);
