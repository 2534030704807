import { contextIdConfig } from '../config';
import { useAuthMutation } from '../../common/apiUtils/queryHelper';
import { TGenerateContextIdPayload } from '../types/types';
import { useQueryClient } from 'react-query';

const {
  create_contextid: { url, scope },
  get_contextids,
} = contextIdConfig;

export const useCreateContextId = () => {
  const queryClient = useQueryClient();

  return useAuthMutation(
    (axiosInstance) => (payload: TGenerateContextIdPayload) =>
      axiosInstance?.post(url, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(get_contextids.queryKey);
      },
    },
    scope,
  );
};
