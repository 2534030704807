import { ITenant } from '@sitecore-ui/portal-singular';
import { useGetTenantsByIds } from 'features/common/services/inventory/tenants';
import { useGetSitecoreContextId } from '../api/useGetSitecoreContextId';
import { useMemo } from 'react';
import { validResources } from '../utils';

export const useEnhancedResources = (sitecoreContextId: string) => {
  const {
    data,
    isLoading: isContextLoading,
    error,
  } = useGetSitecoreContextId({ sitecoreContextId });

  const filteredResources = useMemo(
    () =>
      data?.resources?.filter(
        ({ name }) => validResources[name as keyof typeof validResources],
      ) ?? [],
    [data],
  );

  const tenantIds = useMemo(
    () => filteredResources.flatMap(({ tenantId }) => tenantId) ?? [],
    [filteredResources],
  );

  const tenantsResponses = useGetTenantsByIds(tenantIds, {
    onError: (err) => {
      console.error('Error fetching tenants:', err);
    },
  });

  const tenants = useMemo(
    () => tenantsResponses.map((response) => response?.data?.data ?? []),
    [tenantsResponses],
  );

  const resourcesMap = useMemo(
    () =>
      new Map(
        //@ts-ignore
        tenants.map(({ id, displayName }: Partial<ITenant>) => [
          id,
          displayName,
        ]),
      ),
    [tenants],
  );

  const updatedResources = useMemo(
    () =>
      filteredResources.map((resource) => {
        const displayName = resourcesMap.get(resource.tenantId);
        return displayName
          ? { ...resource, tenantDisplayName: displayName }
          : resource;
      }),
    [filteredResources, resourcesMap],
  );

  const isLoading =
    isContextLoading || tenantsResponses.some((response) => response.isLoading);

  return useMemo(
    () => ({ updatedResources, isLoading, error }),
    [updatedResources, isLoading, error],
  );
};
