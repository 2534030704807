import { Checkbox, Stack, Text } from '@chakra-ui/react';
import { ImageWithSkeleton } from 'features/common/components/ImageWithSkeleton';
import { AddEditScopedContextValues } from 'features/context-ids/hooks/useAddEditScopedContext';
import { SitecoreContextResourceType } from 'features/context-ids/types/types';
import { validResources } from 'features/context-ids/utils';
import { useMemo } from 'react';

export const useScopedResourcesColumns = ({
  setValues,
  values,
}: {
  setValues: (values: AddEditScopedContextValues) => any;
  values: AddEditScopedContextValues;
}) =>
  useMemo(
    () => [
      {
        id: 'selection',
        width: '1%',
        Header: '',
        accessor: (accessor: Partial<SitecoreContextResourceType>) => {
          const id = accessor.id as string;
          const name = accessor.name;

          return (
            <Checkbox
              name={name}
              isChecked={!!values.allowedResourceIds?.includes(id)}
              onChange={(e) => {
                const checked = e.target.checked;
                //@ts-ignore
                setValues((prev) => ({
                  ...prev,
                  allowedResourceIds: checked
                    ? [...prev.allowedResourceIds, id]
                    : prev.allowedResourceIds.filter((i: string) => i !== id),
                }));
              }}
            />
          );
        },
      },
      {
        id: 'name',
        Header: 'App',
        accessor: (accessor: Partial<SitecoreContextResourceType>) => {
          const name = accessor.name;

          const { iconUrl, name: displayName } =
            validResources[name as keyof typeof validResources] ?? {};
          return (
            <Stack direction='row' alignItems='center'>
              <ImageWithSkeleton
                key={displayName}
                width='7'
                height='7'
                src={iconUrl}
                alt={displayName}
                flexShrink={0}
              />
              <Text>{displayName}</Text>
            </Stack>
          );
        },
        disableSortBy: true,
        width: '30%',
      },
      {
        id: 'tenant',
        Header: 'Tenant',
        accessor: 'tenantDisplayName',
        disableSortBy: true,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValues, values.allowedResourceIds?.length],
  );
