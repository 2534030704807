import { Center, Stack, Heading, Image, Button } from '@chakra-ui/react';

export interface StatusViewProps {
  icon: string;
  iconAlt: string;
  title: string;
  action?: {
    label: string;
    onClick: () => void;
  };
}

export const StatusView: React.FC<StatusViewProps> = ({
  icon,
  iconAlt,
  title,
  action,
}) => (
  <Center width='100%' height='100%'>
    <Stack align='center' textAlign='center' spacing='4' maxW='sm'>
      <Image boxSize='16' src={icon} alt={iconAlt} />
      <Stack>
        <Heading size='md'>{title}</Heading>
      </Stack>
      {action && (
        <Button variant='link' onClick={action.onClick}>
          {action.label}
        </Button>
      )}
    </Stack>
  </Center>
);
