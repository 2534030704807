import { adminPath, contextIdsPath } from '../../admin/components/admin-paths';

export const contextIdsRoute = `${adminPath}${contextIdsPath}`;
export const contextIdsAddRoute = `${contextIdsRoute}/create`;
export const getContextIdsDeleteRoute = (sitecoreContextId?: string) =>
  `${contextIdsRoute}/${
    sitecoreContextId ? sitecoreContextId : ':sitecoreContextId'
  }/delete`;

export const getContextIdsDetailsRoute = (sitecoreContextId?: string) =>
  `${contextIdsRoute}/${
    sitecoreContextId ? sitecoreContextId : ':sitecoreContextId'
  }/details`;
export const getContextIdsEditRoute = (sitecoreContextId?: string) =>
  `${contextIdsRoute}/${
    sitecoreContextId ? sitecoreContextId : ':sitecoreContextId'
  }/details/edit`;
export const getContextIdDetailsDeleteRoute = (sitecoreContextId?: string) =>
  `${contextIdsRoute}/${
    sitecoreContextId ? sitecoreContextId : ':sitecoreContextId'
  }/details/delete`;
export const getAddScopedContextRoute = (sitecoreContextId?: string) =>
  `${contextIdsRoute}/${
    sitecoreContextId ? sitecoreContextId : ':sitecoreContextId'
  }/details/scoped-contexts/add`;
export const getEditScopedContextRoute = (
  sitecoreContextId?: string,
  scopeId?: string,
) =>
  `${contextIdsRoute}/${
    sitecoreContextId ? sitecoreContextId : ':sitecoreContextId'
  }/details/scoped-contexts/${scopeId ? scopeId : ':scopeId'}/edit`;
export const getDeleteScopedContextRoute = (
  sitecoreContextId?: string,
  scopeId?: string,
) =>
  `${contextIdsRoute}/${
    sitecoreContextId ? sitecoreContextId : ':sitecoreContextId'
  }/details/scoped-contexts/${scopeId ? scopeId : ':scopeId'}/delete`;
