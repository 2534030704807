import { hostnames } from '../common/config/envConfig';

export function getContextApiUrl() {
  const contextApiBaseUrl = {
    [hostnames.localhost]:
      'https://edge-platform-context-api-staging.sitecore-staging.cloud/api/context/user/v1',
    [hostnames.internal]:
      'https://edge-platform-context-api-staging.sitecore-staging.cloud/api/context/user/v1',
    [hostnames.dev]:
      'https://edge-platform-context-api-staging.sitecore-staging.cloud/api/context/user/v1',
    [hostnames.qa]:
      'https://edge-platform-context-api-staging.sitecore-staging.cloud/api/context/user/v1',
    [hostnames.staging]:
      'https://edge-platform-context-api-staging.sitecore-staging.cloud/api/context/user/v1',
    [hostnames.beta]:
      'https://edge-platform-context-api-beta.sitecorecloud.io/api/context/user/v1',
    [hostnames['pre-production']]:
      'https://edge-platform-context-api-beta.sitecorecloud.io/api/context/user/v1',
    [hostnames.production]:
      'https://edge-platform-context-api.sitecorecloud.io/api/context/user/v1',
  }[window.location.hostname];

  return contextApiBaseUrl || '';
}

const contextIdApiBaseUrl = getContextApiUrl();

//TODO: Add the correct scope for the contextIdConfig

export const contextIdConfig = {
  get_contextids: {
    url: `${contextIdApiBaseUrl}/contexts`,
    queryKey: ['contexts/list'] as const,
    scope: 'ep.usr.ctx:r',
  },
  get_contextid: {
    url: (sitecoreContextId: string) =>
      `${contextIdApiBaseUrl}/contexts/${sitecoreContextId}`,
    queryKey: (sitecoreContextId: string) =>
      [`contexts/context/${sitecoreContextId}`] as const,
    scope: 'ep.usr.ctx:r',
  },
  create_contextid: {
    url: `${contextIdApiBaseUrl}/contexts`,
    scope: 'ep.usr.ctx:w',
  },
  edit_contextid: {
    url: (sitecoreContextId: string) =>
      `${contextIdApiBaseUrl}/contexts/${sitecoreContextId}`,
    scope: 'ep.usr.ctx:w',
  },
  delete_contextid: {
    url: (sitecoreContextId: string) =>
      `${contextIdApiBaseUrl}/contexts/${sitecoreContextId}`,
    scope: 'ep.usr.ctx:w',
  },
  create_scoped_context: {
    url: (sitecoreContextId: string) =>
      `${contextIdApiBaseUrl}/contexts/${sitecoreContextId}/filtered`,
    scope: 'ep.usr.ctx:w',
  },
  get_scoped_contexts: {
    url: (sitecoreContextId: string) =>
      `${contextIdApiBaseUrl}/contexts/${sitecoreContextId}/filtered`,
    queryKey: (sitecoreContextId: string) => [
      'filtered_contexts/list',
      sitecoreContextId,
    ],
    scope: 'ep.usr.ctx:r',
  },
  get_scoped_context: {
    url: (sitecoreContextId: string, scopeId: string) =>
      `${contextIdApiBaseUrl}/contexts/${sitecoreContextId}/filtered/${scopeId}`,
    queryKey: (sitecoreContextId: string, scopeId: string) =>
      [
        `contexts/scoped-contexts/${sitecoreContextId}`,
        `filtered/${scopeId}`,
      ] as const,
    scope: 'ep.usr.ctx:r',
  },
  edit_scoped_context: {
    url: (sitecoreContextId: string, scopeId: string) =>
      `${contextIdApiBaseUrl}/contexts/${sitecoreContextId}/filtered/${scopeId}`,
    scope: 'ep.usr.ctx:w',
  },
  delete_scoped_context: {
    url: (sitecoreContextId: string, scopeId: string) =>
      `${contextIdApiBaseUrl}/contexts/${sitecoreContextId}/filtered/${scopeId}`,
    scope: 'ep.usr.ctx:w',
  },
};
