import { Box, Flex, Heading, Link, Skeleton, Text } from '@chakra-ui/react';
import { useGetCurrentOrg } from 'features/home/services/identity/organization';

const UnifiedTracking = () => {
  const { isLoading: isGetCurrentOrganizationLoading } = useGetCurrentOrg();

  return (
    <Box
      h='full'
      overflow='auto'
      layerStyle='section.main'
      bg='chakra-subtle-bg'
    >
      <Flex direction='column' mb='10' gap='1'>
        <Heading as='h1'>
          Unified tracking
          <Skeleton
            isLoaded={!isGetCurrentOrganizationLoading}
            //@ts-ignore
            display='inline-block'
          ></Skeleton>
        </Heading>
        <Text variant='subtle'>
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.
          Quisque volutpat mattis eros. Nullam malesuada erat ut turpis.
          <Link color='primary.500' href='#' isExternal>
            Learn more
          </Link>
          <Skeleton
            isLoaded={!isGetCurrentOrganizationLoading}
            //@ts-ignore
            display='inline-block'
          ></Skeleton>
        </Text>
      </Flex>

      <Heading as='h6' size='sm'>
        Content
      </Heading>
    </Box>
  );
};

export default UnifiedTracking;
