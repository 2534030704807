import { DrawerBody, Flex, Stack, Text } from '@chakra-ui/react';
import { InputFormControl } from 'features/common/components/Form';
import { AddEditScopedContextValues } from 'features/context-ids/hooks/useAddEditScopedContext';
import { SitecoreContextResourceType } from 'features/context-ids/types/types';
import { FormikErrors } from 'formik';
import EmptyState from 'features/common/components/EmptyState';
import { DataTable } from 'features/common/components/DataTable';
import { useScopedResourcesColumns } from '../../columns/useScopedResourcesColumns';

const NAME = 'name';
const SCOPE_ID = 'scopeId';
// eslint-disable-next-line no-empty-function
const onChange = () => {};

export const AddEditScopedContextDrawerBody: React.FC<{
  errors: FormikErrors<AddEditScopedContextValues>;
  values: AddEditScopedContextValues;
  setValues: (values: AddEditScopedContextValues) => any;
  isLoading: boolean;
  isInEditMode: boolean;
  handleChange: (e: React.ChangeEvent<any>) => void;
  scopedContextData: SitecoreContextResourceType[];
}> = ({
  errors,
  values,
  setValues,
  handleChange,
  isInEditMode,
  isLoading,
  scopedContextData,
}) => {
  const columns = useScopedResourcesColumns({ setValues, values });

  return (
    <DrawerBody>
      <Stack gap={4}>
        <Text>
          {isInEditMode
            ? 'You can edit the name and resource.'
            : 'Select all the resource that apply and create a scope name.'}
        </Text>
        <Flex>
          {!isLoading && scopedContextData.length === 0 ? (
            <EmptyState
              text='No resources available'
              dataTestId='no-resources-available'
            />
          ) : (
            <DataTable
              columns={columns}
              onChange={onChange}
              isLoading={isLoading}
              data={scopedContextData}
              pagination='hidden'
              data-behavior-analytics-feature='scoped context table'
            />
          )}
        </Flex>
        <Flex pl='5' direction='column' gap={5}>
          <InputFormControl
            {...{
              isInvalid: Boolean(errors.name),
              label: 'Scope name',
              name: NAME,
              value: values.name,
              onChange: handleChange,
              error: errors.name,
              maxW: 'xl',
              placeholder: 'Enter name',
            }}
          />
          {isInEditMode && (
            <InputFormControl
              {...{
                label: 'Scope ID',
                name: SCOPE_ID,
                value: values.scopeId,
                maxW: 'xl',
                isDisabled: true,
              }}
            />
          )}
        </Flex>
      </Stack>
    </DrawerBody>
  );
};
