import {
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { ImageWithSkeleton } from 'features/common/components/ImageWithSkeleton';
import { history } from 'features/common/history/history';
import {
  getDeleteScopedContextRoute,
  getEditScopedContextRoute,
} from 'features/context-ids/routes';
import { FilteredContextWithResources } from 'features/context-ids/types/types';
import { validResources } from 'features/context-ids/utils';

const ResourcesCellPopover = ({
  resources,
}: Partial<FilteredContextWithResources>) => (
  <Popover trigger='hover'>
    <PopoverTrigger>
      <Text cursor='pointer' decoration='underline' width='fit-content'>
        {resources?.length} resources
      </Text>
    </PopoverTrigger>
    <PopoverContent minW='md'>
      <PopoverArrow />
      <PopoverBody>
        <Flex direction='column' gap={4} justifyContent='center' pb='4'>
          <Flex justifyContent='space-between' m={2}>
            <Text fontSize={13} color='neutral-fg' fontWeight='bold'>
              Resources
            </Text>
            <Text fontSize={13} color='neutral-fg' fontWeight='bold'>
              Tenants
            </Text>
          </Flex>
          {resources?.map(({ name, tenantDisplayName, tenantId }, i) => {
            const { iconUrl, name: displayName } =
              validResources[name as keyof typeof validResources] ?? {};

            return (
              <Flex
                key={`${tenantId}- ${i}`}
                gap={5}
                justifyContent='space-between'
              >
                <Flex gap={2} alignItems='center'>
                  <ImageWithSkeleton
                    key={displayName}
                    width='7'
                    height='7'
                    src={iconUrl}
                    alt={displayName}
                    flexShrink={0}
                  />
                  <Text>{displayName}</Text>
                </Flex>
                <Text alignSelf='center'>{tenantDisplayName}</Text>
              </Flex>
            );
          })}
        </Flex>
      </PopoverBody>
    </PopoverContent>
  </Popover>
);

const ResourcesCellData = ({
  resources,
}: Partial<FilteredContextWithResources>) => (
  <Flex direction='column' gap={2} justifyContent='center'>
    {resources?.map(({ name, tenantDisplayName, tenantId }, i) => {
      const { iconUrl, name: displayName } =
        validResources[name as keyof typeof validResources] ?? {};

      return (
        <Flex key={`${tenantId}- ${i}`} gap={2} alignItems='center'>
          <ImageWithSkeleton
            key={displayName}
            width='7'
            height='7'
            src={iconUrl}
            alt={displayName}
            flexShrink={0}
          />
          <Text>{displayName}</Text>
          <Text maxW='sm' isTruncated>
            {tenantDisplayName}
          </Text>
        </Flex>
      );
    })}
  </Flex>
);

export const getTableOptions = () => ({
  rowActions: ({ original }: { original: FilteredContextWithResources }) => {
    const { masterSitecoreContextId, sitecoreContextId } = original;
    return [
      {
        id: '0',
        label: 'Edit',
        onClick: () => {
          history.push({
            pathname: getEditScopedContextRoute(
              masterSitecoreContextId,
              sitecoreContextId,
            ),
            search: history.location.search,
          });
        },
        'data-behavior-analytics-id': 'edit scoped context id',
      },
      {
        id: '1',
        label: 'Delete',
        onClick: () => {
          history.push({
            pathname: getDeleteScopedContextRoute(
              masterSitecoreContextId,
              sitecoreContextId,
            ),
            search: history.location.search,
          });
        },
        'data-behavior-analytics-id': 'delete scoped context id',
      },
    ];
  },
  columns: [
    {
      id: 'id',
      Header: 'Scoped context ID',
      accessor: ({ sitecoreContextId }: FilteredContextWithResources) => (
        <Text maxW='sm' isTruncated>
          {sitecoreContextId}
        </Text>
      ),
      width: '25%',
      disableSortBy: true,
    },
    {
      id: 'name',
      Header: 'Scoped context name',

      accessor: ({ name }: FilteredContextWithResources) => (
        <Text maxW='sm' isTruncated>
          {name}
        </Text>
      ),
      width: '25%',
      disableSortBy: true,
    },
    {
      id: 'resources',
      Header: 'Resources applied',
      accessor: ({ resources }: FilteredContextWithResources) =>
        resources.length >= 3 ? (
          <ResourcesCellPopover resources={resources} />
        ) : (
          <ResourcesCellData resources={resources} />
        ),
      disableSortBy: true,
    },
  ],
});
