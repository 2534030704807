import { Flex, Heading } from '@chakra-ui/react';
import EmptyState from 'features/common/components/EmptyState';
import { useMemo } from 'react';
import { DataTable } from '../../../../../common/components/DataTable';
import { SitecoreContextResourceType } from '../../../../types/types';
import { generateResourcesTableData, columns } from './helpers';

// eslint-disable-next-line no-empty-function
const onChange = () => {};

export const ResourcesTable = ({
  resources = [],
  isLoading,
}: {
  resources?: SitecoreContextResourceType[];
  isLoading: boolean;
}) => {
  const tableData = useMemo(
    () => generateResourcesTableData(resources),
    [resources],
  );

  return (
    <Flex direction='column' gap={6}>
      <Heading size='md' data-testid='app-details-header-app-name'>
        Resources
      </Heading>
      {tableData.length || isLoading ? (
        <DataTable
          columns={columns}
          onChange={onChange}
          isLoading={isLoading}
          pageSize={5}
          data={tableData}
          pagination='hidden'
          data-behavior-analytics-feature='resources table'
        />
      ) : (
        <Flex my={5}>
          <EmptyState
            text='No scoped context'
            description='In order to create scoped context, first add a resource'
            boxSize='20'
            dataTestId='no-resource-container'
          />
        </Flex>
      )}
    </Flex>
  );
};
