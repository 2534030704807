import {
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { mdiDotsHorizontal } from '@mdi/js';
import { PortalIcon } from 'features/common/components/PortalIcon';
import { history } from 'features/common/history/history';
import {
  getContextIdDetailsDeleteRoute,
  getContextIdsEditRoute,
} from 'features/context-ids/routes';
import { ContextNameSection } from '../ContextNameSection';

const actions = [
  {
    id: 'edit-context-id-action-context-id-details-page',
    label: 'Edit',
    getPath: getContextIdsEditRoute,
    dataBehaviorAnalyticsId: 'edit-context-id-action-context-id-details-page',
  },
  {
    id: 'delete-context-id-action-context-id-details-page',
    label: 'Delete',
    getPath: getContextIdDetailsDeleteRoute,
    dataBehaviorAnalyticsId: 'edit-context-id-action-context-id-details-page',
  },
];

export const Header = ({ name }: { name: string }) => (
  <Flex data-testid='app-details-header' justifyContent='space-between'>
    <ContextNameSection name={name} />
    <Menu placement='bottom-end'>
      <MenuButton
        onClick={(event) => event.stopPropagation()}
        as={IconButton}
        size='sm'
        variant='ghost'
        icon={<PortalIcon path={mdiDotsHorizontal} />}
      />
      <MenuList>
        {actions.map(({ id, label, getPath, dataBehaviorAnalyticsId }) => (
          <MenuItem
            key={id}
            onClick={() =>
              history.push({
                pathname: getPath(name),
                search: history.location.search,
              })
            }
            data-behavior-analytics-id={dataBehaviorAnalyticsId}
          >
            {label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  </Flex>
);
