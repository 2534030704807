import { useAuthQueries, useAuthQuery } from '../../apiUtils/queryHelper';
import { UseQueryOptions } from 'react-query';
import { AxiosResponse } from 'axios';
import { useAuth0TokenClaims } from 'features/common/auth/claims';
import { env } from 'features/common/config/envConfig';
import {
  ITenant,
  TokenCustomClaimKeysEnum,
  IResponse,
  IGetTenantsRequestPayload,
  inventory as inventoryConfig,
  createInventoryPostTenantsBodyDTO,
  Environment,
  getInventoryBaseUrl,
} from '@sitecore-ui/portal-singular';

const inventoryBaseUrl = getInventoryBaseUrl(env);
const inventory = inventoryConfig(env);

export const useGetTenantsByIds = (
  ids: string[],
  useQueryOptions?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<IResponse<ITenant>>,
          unknown,
          AxiosResponse<IResponse<ITenant>>,
          any[]
        >,
        'queryKey' | 'queryFn'
      >
    | undefined,
) =>
  useAuthQueries(
    ids.map((id) => {
      return {
        key: ['tenants', id],
        fetcher: async (axiosInstance) => {
          return axiosInstance?.get(`${inventoryBaseUrl}/tenants/${id}`);
        },
        options: {
          enabled: Boolean(id),
          ...useQueryOptions,
        },
        scope: inventory.get_tenants.scope,
      };
    }),
  );

export const useGetTenants = (
  { pageNumber, pageSize, serviceId }: IGetTenantsRequestPayload,
  useQueryOptions?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<IResponse<ITenant>>,
          unknown,
          AxiosResponse<IResponse<ITenant>>,
          any[]
        >,
        'queryKey' | 'queryFn'
      >
    | undefined,
) => {
  const { claims } = useAuth0TokenClaims();
  const environment =
    env?.toLowerCase() === 'dev' || env?.toLowerCase() === 'qa'
      ? 'staging'
      : env?.toLowerCase();
  const organizationId =
    (claims && claims[TokenCustomClaimKeysEnum.ORG_ID]) || '';

  const enabledProducts = (window as any).ENABLED_PRODUCTS;
  const hiddenProducts = (window as any).NOT_DISPLAYABLE_PRODUCTS;

  return useAuthQuery(
    ['tenants', pageNumber, pageSize, organizationId, serviceId],
    async (axiosInstance) => {
      return axiosInstance?.post(
        inventory.get_tenants.url(pageNumber, pageSize),
        createInventoryPostTenantsBodyDTO({
          organizationId,
          environment: [Environment.dev, Environment.qa].includes(environment)
            ? 'staging'
            : environment,
          enabledProducts,
          hiddenProducts,
        }),
      );
    },
    {
      enabled: Boolean(organizationId),
      ...useQueryOptions,
    },
    inventory.get_tenants.scope,
  );
};

export const useGetTenantsWithEmbedded = (
  { pageNumber, pageSize, serviceId }: IGetTenantsRequestPayload,
  useQueryOptions?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<IResponse<ITenant>>,
          unknown,
          AxiosResponse<IResponse<ITenant>>,
          any[]
        >,
        'queryKey' | 'queryFn'
      >
    | undefined,
) => {
  const { claims } = useAuth0TokenClaims();
  const environment = env?.toLowerCase();
  const organizationId =
    (claims && claims[TokenCustomClaimKeysEnum.ORG_ID]) || '';
  const enabledProducts = (window as any).ENABLED_PRODUCTS;

  return useAuthQuery(
    ['tenants', 'allTenants', pageNumber, pageSize, organizationId, serviceId],
    async (axiosInstance) => {
      return axiosInstance?.post(
        inventory.get_tenants.url(pageNumber, pageSize),
        createInventoryPostTenantsBodyDTO({
          organizationId,
          environment: [Environment.dev, Environment.qa].includes(environment)
            ? 'staging'
            : environment,
          enabledProducts,
          hiddenProducts: [],
        }),
      );
    },
    {
      enabled: Boolean(organizationId),
      ...useQueryOptions,
    },
    inventory.get_tenants.scope,
  );
};
