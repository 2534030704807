import {
  Flex,
  Heading,
  ButtonGroup,
  Button,
  Text,
  Box,
} from '@chakra-ui/react';
import { mdiPlus } from '@mdi/js';
import { PortalIcon } from 'features/common/components/PortalIcon';
import { history } from 'features/common/history/history';
import { contextIdsAddRoute } from 'features/context-ids/routes';
import { ReactNode } from 'react';
import { ContextIdsTable } from './components/ContextIdsTable';

const Header = ({ children }: { children: ReactNode }) => (
  <Flex
    flexDirection={['column', 'column', 'row']}
    justifyContent='space-between'
    marginBottom={12}
    gap={[6, 6, 0]}
  >
    {children}
  </Flex>
);

const onClickAdd = () =>
  history.push({
    pathname: contextIdsAddRoute,
    search: history.location.search,
  });

export const ContextIds = () => (
  <Flex direction='column' h='100%'>
    <Header>
      <Box>
        <Heading as='h1'>Context IDs</Heading>
        <Text mt={5} maxW='3xl'>
          A Context ID is a unified identifier that enables your application to
          access your organization's resources. When you create a Context ID,
          you specify the resources that the Context ID will grant access to.
        </Text>
      </Box>
      <ButtonGroup>
        <Button
          variant='solid'
          onClick={onClickAdd}
          leftIcon={<PortalIcon path={mdiPlus} />}
          data-testid='create-context-id-toggle'
          data-behavior-analytics-id='Add context ID button - Context IDs page'
        >
          Add context ID
        </Button>
      </ButtonGroup>
    </Header>
    <ContextIdsTable />
  </Flex>
);
