import { contextIdConfig } from '../config';
import { useAuthMutation } from '../../common/apiUtils/queryHelper';
import { TCreateScopedContextPayload } from '../types/types';
import { useQueryClient } from 'react-query';

const {
  create_scoped_context: { url, scope },
  get_scoped_contexts,
} = contextIdConfig;

export const useCreateScopedContext = ({
  sitecoreContextId,
}: {
  sitecoreContextId: string;
}) => {
  const queryClient = useQueryClient();

  return useAuthMutation(
    (axiosInstance) => (payload: TCreateScopedContextPayload) =>
      axiosInstance?.post(url(sitecoreContextId), payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          get_scoped_contexts.queryKey(sitecoreContextId),
        );
      },
    },
    scope,
  );
};
