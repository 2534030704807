import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import { useParams } from 'react-router';
import { history } from 'features/common/history/history';
import { Header } from './components/Header';
import { contextIdsRoute } from 'features/context-ids/routes';
import { variants } from 'features/common/components/GenericErrorTemplate/resources';
import { StatusView } from 'features/common/components/StatusView';
import { ResourcesTable } from './components/ResourcesTable';
import { useEnhancedResources } from 'features/context-ids/hooks/useEnhancedResources';
import { useGetSitecoreContextId } from 'features/context-ids/api/useGetSitecoreContextId';
import { FilteredContextsTable } from './components/FilteredContextsTable';

export const ContextIdDetails = () => {
  const { sitecoreContextId } = useParams<{ sitecoreContextId: string }>();

  const { data } = useGetSitecoreContextId({ sitecoreContextId });
  const { name, description } = data ?? {};
  const { updatedResources, isLoading, error } =
    useEnhancedResources(sitecoreContextId);

  if (error)
    return (
      <>
        <BreadcrumbMenu currentItem={sitecoreContextId} />
        <StatusView
          icon={variants['bad-request'].illustration.colored}
          iconAlt='alert icon'
          title='Failed to fetch this Context ID'
        />
      </>
    );

  return (
    <Flex flexDir='column' gap={10}>
      <BreadcrumbMenu currentItem={sitecoreContextId} />
      <Header name={sitecoreContextId} />
      <Flex
        data-testid='context-id-details-body'
        direction='column'
        gap={10}
        flexWrap='wrap'
      >
        <Flex direction='column' gap={2}>
          <ContextIdDetailsField
            label='Name'
            value={name}
            isLoading={isLoading}
            gap={14}
          />
          <ContextIdDetailsField
            label='Description'
            value={description}
            isLoading={isLoading}
            gap={4}
          />
        </Flex>
        <ResourcesTable resources={updatedResources} isLoading={isLoading} />
        <FilteredContextsTable resources={updatedResources} />
      </Flex>
    </Flex>
  );
};

const BreadcrumbMenu = ({ currentItem }: { currentItem: string }) => (
  <Breadcrumb data-testid='app-details-breadcrumb'>
    <BreadcrumbItem>
      <BreadcrumbLink
        onClick={() =>
          history.push({
            pathname: contextIdsRoute,
            search: history.location.search,
          })
        }
      >
        Context IDs
      </BreadcrumbLink>
    </BreadcrumbItem>
    <BreadcrumbItem isCurrentPage>
      <BreadcrumbLink href='#'>{currentItem}</BreadcrumbLink>
    </BreadcrumbItem>
  </Breadcrumb>
);

const ContextIdDetailsField = ({
  label,
  value,
  isLoading,
  gap,
}: {
  label: string;
  value?: string;
  isLoading: boolean;
  gap: number;
}) => {
  if (!value && !isLoading) return null;
  return isLoading ? (
    <Skeleton height={5} width='200px' />
  ) : (
    <Flex gap={gap}>
      <Text variant='subtle' fontWeight='bold'>
        {label}
      </Text>
      <Text>{value}</Text>
    </Flex>
  );
};
