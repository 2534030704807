import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { isEqual } from 'lodash';
import { useEditScopedContext } from '../api/useEditScopedContext';
import { AddEditScopedContextValues } from './useAddEditScopedContext';
import { useGetScopedContext } from '../api/useGetScopedContext';
import { useToast } from '@chakra-ui/react';

export const useHandleEditScopedContext = ({
  onCloseDrawer,
  setValues,
  isInEditMode,
  values,
}: {
  setValues: (values: AddEditScopedContextValues) => void;
  isInEditMode: boolean;
  values: AddEditScopedContextValues;
  onCloseDrawer: () => void;
}) => {
  const { sitecoreContextId, scopeId } = useParams<{
    sitecoreContextId: string;
    scopeId: string;
  }>();
  const toast = useToast();

  const { mutate: editScopedContext, isLoading: isEditScopedContextLoading } =
    useEditScopedContext({
      sitecoreContextId,
      scopeId,
    });

  const { data: getScopedContextData, isLoading: isScopedContextLoading } =
    useGetScopedContext({ sitecoreContextId, scopeId });

  useEffect(() => {
    if (getScopedContextData && isInEditMode) {
      setValues({
        scopeId: getScopedContextData.sitecoreContextId || '',
        name: getScopedContextData.name || '',
        allowedResourceIds: getScopedContextData.allowedResourceIds || [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getScopedContextData]);

  const editInitialValues = {
    name: getScopedContextData?.name || '',
    allowedResourceIds: getScopedContextData?.allowedResourceIds || [],
  };

  const isScopeNameEqual = isEqual(editInitialValues.name, values.name);

  const isAllowedResourceIdsEqual = isEqual(
    editInitialValues.allowedResourceIds.sort(),
    values.allowedResourceIds.sort(),
  );

  function handleSubmitEdit() {
    const payload = {
      name: values.name,
      allowedResourceIds: values.allowedResourceIds,
    };

    editScopedContext(payload, {
      onSuccess: () => {
        toast({
          status: 'success',
          description: 'Scope ID edited successfully',
        });
        onCloseDrawer();
      },
      onError: (err) => {
        //@ts-ignore
        const description = err?.message || 'Failed edit scoped context';
        toast({
          status: 'error',
          description,
        });
      },
    });
  }

  const isFieldEqual = isAllowedResourceIdsEqual && isScopeNameEqual;

  const isLoading = isEditScopedContextLoading || isScopedContextLoading;

  return {
    isLoading,
    isDisabledInEditMode: isFieldEqual && isInEditMode,
    handleSubmitEdit,
  };
};
