import { AxiosResponse } from 'axios';
import { useAuthQuery } from 'features/common/apiUtils/graphQueryHelper';
import { UseQueryOptions } from 'react-query';
import { contextIdConfig } from '../config';
import { FilteredContext } from '../types/types';

const {
  get_scoped_contexts: { url, queryKey, scope },
} = contextIdConfig;

export const useGetFilteredContexts = (
  sitecoreContextId: string,
  queryOptions?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<FilteredContext[]>,
          unknown,
          AxiosResponse<FilteredContext[]>,
          string[]
        >,
        'queryKey' | 'queryFn'
      >
    | undefined,
) =>
  useAuthQuery(
    queryKey(sitecoreContextId),
    async (axiosInstance) => {
      return axiosInstance?.get<FilteredContext[]>(url(sitecoreContextId));
    },
    {
      enabled: !!sitecoreContextId,
      ...queryOptions,
    },
    scope,
  );
