import { useParams } from 'react-router-dom';
import { useGetSitecoreContextId } from '../api/useGetSitecoreContextId';
import { useEffect } from 'react';
import { AddEditContextIdValues } from './useAddEditContextId';
import { useEditContextId } from '../api/useEditContextId';
import { pickObjectsDifferences } from '../utils';
import { isEmpty } from 'lodash';

export const useHandleEditContextId = ({
  setValues,
  isInEditMode,
  values,
}: {
  setValues: (values: AddEditContextIdValues) => void;
  isInEditMode: boolean;
  values: AddEditContextIdValues;
}) => {
  const { sitecoreContextId } = useParams<{ sitecoreContextId: string }>();

  const {
    mutate: editSitecoreContextId,
    isLoading: isEditSitecoreContextIdLoading,
  } = useEditContextId({
    sitecoreContextId,
  });

  const { data: contextIdData, isLoading: isGetContextIdLoading } =
    useGetSitecoreContextId({
      sitecoreContextId,
      enabled: isInEditMode,
    });

  useEffect(() => {
    if (contextIdData && isInEditMode) {
      setValues({
        sitecoreContextId: contextIdData.sitecoreContextId || '',
        name: contextIdData.name || '',
        description: contextIdData.description || '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextIdData]);

  const editInitialValues = {
    name: contextIdData?.name || '',
    description: contextIdData?.description || '',
  };
  const selectedValues = {
    name: values.name,
    description: values.description,
  };
  const updatedValues = pickObjectsDifferences(
    selectedValues,
    editInitialValues,
  );

  const isLoading = isEditSitecoreContextIdLoading || isGetContextIdLoading;

  return {
    isLoading,
    editSitecoreContextId,
    updatedValues,
    isDisabledInEditMode: isEmpty(updatedValues) && isInEditMode,
  };
};
