import {
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
  ButtonGroup,
  Button,
} from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AddEditScopedContextDrawerBody } from './AddEditScopedContextDrawerBody';
import { getContextIdsDetailsRoute } from 'features/context-ids/routes';
import { useAddEditScopedContext } from 'features/context-ids/hooks/useAddEditScopedContext';

export const AddEditScopedContextDrawer = () => {
  const { sitecoreContextId } = useParams<{ sitecoreContextId: string }>();
  const history = useHistory();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const onCloseDrawer = useCallback(() => {
    history.push({
      pathname: getContextIdsDetailsRoute(sitecoreContextId),
      search: history.location.search,
    });
    onClose();
  }, [history, onClose, sitecoreContextId]);

  useEffect(() => {
    if (isOpen) return;
    onOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    handleSubmit,
    isLoading,
    values,
    errors,
    handleChange,
    setValues,
    isInEditMode,
    isValid,
    scopedContextData,
    isDisabledInEditMode,
    isResourcesDataLoading,
  } = useAddEditScopedContext({ onCloseDrawer });

  return (
    <Drawer onClose={onCloseDrawer} isOpen={isOpen} size='lg'>
      <form
        onSubmit={handleSubmit}
        data-testid='add-edit-scoped-context-drawer-form'
      >
        <DrawerOverlay />
        <DrawerContent
          data-testid='add-edit-scoped-context-drawer-content'
          data-behavior-analytics-feature='Add edit scoped context drawer'
        >
          <DrawerHeader display='flex' alignItems='center'>
            {`${isInEditMode ? 'Edit scoped context' : 'Add scoped context'}`}
          </DrawerHeader>
          <AddEditScopedContextDrawerBody
            {...{
              errors,
              values,
              isLoading: isResourcesDataLoading,
              setValues,
              handleChange,
              isInEditMode,
              scopedContextData,
            }}
          />
          <DrawerFooter justifyContent='flex-end'>
            <ButtonGroup>
              <Button
                data-testid='add-edit-scoped-context-drawer-cancel-btn'
                type='button'
                variant='ghost'
                onClick={onCloseDrawer}
                isDisabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                type='submit'
                variant='solid'
                data-testid='add-edit-scoped-context-drawer-save-btn'
                isLoading={isLoading}
                isDisabled={!isValid || isDisabledInEditMode}
              >
                Save
              </Button>
            </ButtonGroup>
          </DrawerFooter>
        </DrawerContent>
      </form>
    </Drawer>
  );
};
