import { generateContextId } from './../utils';
import { useFormik } from 'formik';
import { array, object, string } from 'yup';
import { useParams, useRouteMatch } from 'react-router-dom';
import { getEditScopedContextRoute } from '../routes';
import { useToast } from '@chakra-ui/react';
import { useCreateScopedContext } from '../api/useCreateScopedContext';
import { useEnhancedResources, useHandleEditScopedContext } from '.';
import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

const initialValues = {
  name: '',
  allowedResourceIds: [] as string[],
  scopeId: '',
};

export type AddEditScopedContextValues = typeof initialValues;

export const useAddEditScopedContext = ({
  onCloseDrawer,
}: {
  onCloseDrawer: () => void;
}) => {
  const { sitecoreContextId } = useParams<{
    sitecoreContextId: string;
    scopeId: string;
  }>();
  const toast = useToast();
  const isInEditMode = Boolean(useRouteMatch(getEditScopedContextRoute()));

  const {
    mutate: createScopedContext,
    isLoading: isCreateScopedContextLoading,
  } = useCreateScopedContext({ sitecoreContextId });

  const { isLoading: isResourcesDataLoading, updatedResources } =
    useEnhancedResources(sitecoreContextId);

  const scopedContextData = useMemo(
    () =>
      updatedResources.map((r) => ({
        ...r,
        isScopedResource: false,
      })),
    [updatedResources],
  );

  const validationSchema = addEditScopedContextValidationSchema();

  const {
    errors,
    values,
    isValid,
    setValues,
    handleChange,
    setFieldValue,
    handleSubmit: formikHandleSubmit,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: false,
    onSubmit: async (values) => {
      onSubmit(values);
    },
  });
  const {
    isDisabledInEditMode,
    handleSubmitEdit,
    isLoading: isEditScopeContextLoading,
  } = useHandleEditScopedContext({
    setValues,
    isInEditMode,
    values,
    onCloseDrawer,
  });

  function onScopedContextCreationSubmit(values: AddEditScopedContextValues) {
    const guid = uuidv4();
    const scopeId = generateContextId(guid);
    const payload = {
      name: values.name,
      allowedResourceIds: values.allowedResourceIds,
      sitecoreContextId: scopeId,
    };

    createScopedContext(payload, {
      onSuccess: () => {
        toast({
          status: 'success',
          description: 'Scoped context added successfully.',
        });
        onCloseDrawer();
      },
      onError: (err) => {
        toast({
          status: 'error',
          description:
            //@ts-ignore
            err?.response?.data?.detail || 'Failed to add scoped context',
        });
      },
    });
  }

  function onSubmit(values: AddEditScopedContextValues) {
    if (isInEditMode) {
      handleSubmitEdit();
      return;
    }
    onScopedContextCreationSubmit(values);
  }

  const isLoading =
    isCreateScopedContextLoading ||
    isResourcesDataLoading ||
    isEditScopeContextLoading;

  return {
    setValues,
    handleChange,
    setFieldValue,
    handleSubmit: formikHandleSubmit,
    errors,
    values,
    isValid,
    isLoading,
    isInEditMode,
    scopedContextData,
    isResourcesDataLoading,
    isDisabledInEditMode,
  };
};

const addEditScopedContextValidationSchema = () =>
  object({
    name: string().optional().max(80, 'Name must be less than 80 characters'),
    allowedResourceIds: array().of(string().optional()),
  });
