import { contextIdConfig } from '../config';
import { useAuthQuery } from '../../common/apiUtils/queryHelper';
import { TScopedContext } from '../types/types';

const { url, queryKey } = contextIdConfig.get_scoped_context;

export const useGetScopedContext = ({
  sitecoreContextId,
  scopeId,
  enabled = true,
}: {
  sitecoreContextId: string;
  scopeId: string;
  enabled?: boolean;
}) => {
  const isEnabled = enabled && !!sitecoreContextId && !!scopeId;

  return useAuthQuery<TScopedContext>(
    queryKey(sitecoreContextId, scopeId),
    async (axiosInstance) => {
      const response = await axiosInstance?.get(
        url(sitecoreContextId, scopeId),
      );
      return response?.data;
    },
    {
      enabled: isEnabled,
    },
  );
};
