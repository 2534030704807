import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  Text,
  AlertDialogBody,
  AlertDialogFooter,
  ButtonGroup,
  Button,
  Input,
  FormControl,
  useToast,
} from '@chakra-ui/react';
import { history } from 'features/common/history/history';
import { useRef, useState } from 'react';
import { T } from '@transifex/react';
import { useDeleteScopedContext } from '../../../../api/useDeleteScopedContext';
import { useParams } from 'react-router';
import { t } from '@transifex/native';

export const DeleteScopedContextModal = () => {
  const [inputValue, setInputValue] = useState('');
  const lessDestructiveRef = useRef(null);

  const toast = useToast();
  const { sitecoreContextId, scopeId } = useParams<{
    sitecoreContextId: string;
    scopeId: string;
  }>();
  const {
    mutate: deleteScopedContextMutation,
    isLoading: isLoadingDeleteRequest,
  } = useDeleteScopedContext({
    sitecoreContextId,
    scopeId,
    mutationOptions: {
      onSuccess: () => {
        history.goBack();
        // history.replace(`${contextIdsRoute}${history.location.search}`);
        toast({
          description: t(`Scoped Context ID ${sitecoreContextId} deleted.`),
          status: 'success',
        });
      },
      onError: () => {
        toast({
          description: t(
            `Scoped Context ID ${sitecoreContextId} deletion failed.`,
          ),
          status: 'error',
        });
      },
    },
  });

  const onClose = () => history.goBack();

  const handleDelete = () => {
    if (inputValue === 'delete') deleteScopedContextMutation();
  };
  return (
    <AlertDialog
      isOpen={true}
      onClose={onClose}
      leastDestructiveRef={lessDestructiveRef}
      size='xl'
      autoFocus={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent data-testid='delete-scoped-context-modal' gap={2}>
          <AlertDialogHeader>
            <T _str='Delete scoped context' />
          </AlertDialogHeader>
          <AlertDialogBody>
            <Text>
              Are you sure you want to delete this scoped context? This action
              is permanent and cannot be undone.
            </Text>
            <FormControl py={8}>
              <Text>Type "delete" to confirm</Text>
              <Input
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                mt={2}
              />
            </FormControl>
          </AlertDialogBody>
          <AlertDialogFooter>
            <ButtonGroup>
              <Button
                variant='ghost'
                data-testid='delete-scoped-context-modal-cancel-btn'
                onClick={onClose}
                isDisabled={isLoadingDeleteRequest}
              >
                Cancel
              </Button>
              <Button
                colorScheme='danger'
                variant='solid'
                data-testid='delete-scoped-context-modal-delete-btn'
                onClick={handleDelete}
                isDisabled={inputValue !== 'delete' || isLoadingDeleteRequest}
                isLoading={isLoadingDeleteRequest}
              >
                Delete
              </Button>
            </ButtonGroup>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
