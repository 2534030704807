import { Flex, Tag, Text } from '@chakra-ui/react';
import { ImageWithSkeleton } from 'features/common/components/ImageWithSkeleton';
import { validResources } from 'features/context-ids/utils';
import { history } from '../../../../../../common/history/history';
import {
  getContextIdsDeleteRoute,
  getContextIdsDetailsRoute,
} from '../../../../../routes';
import {
  SitecoreContextMappingType,
  SitecoreContextType,
} from '../../../../../types/types';

export const getTableOptions = () => ({
  rowActions: ({ original }: { original: SitecoreContextMappingType }) => {
    const { sitecoreContextId, type: sitecoreContextType } = original;
    return [
      {
        id: '0',
        label: 'View Details Page',
        onClick: () => {
          history.push({
            pathname: getContextIdsDetailsRoute(sitecoreContextId),
            search: history.location.search,
          });
        },
        'data-behavior-analytics-id': 'view details page - context id',
      },
      ...(sitecoreContextType === 'custom'
        ? [
            {
              id: '1',
              label: 'Delete',
              onClick: () => {
                history.push({
                  pathname: getContextIdsDeleteRoute(sitecoreContextId),
                  search: history.location.search,
                });
              },
              'data-behavior-analytics-id':
                'view details page - delete context id',
            },
          ]
        : []),
    ];
  },
  columns: [
    {
      id: 'sitecoreContextId',
      Header: 'Context ID',
      accessor: renderContextIdCell,
      disableSortBy: true,
    },
    {
      id: 'name',
      Header: 'Name',
      accessor: 'name',
      disableSortBy: true,
    },
    {
      id: 'description',
      Header: 'Description',
      accessor: ({ description }: Partial<SitecoreContextType>) => (
        <Text maxW='sm' isTruncated>
          {description}
        </Text>
      ),
      disableSortBy: true,
    },
    {
      id: 'resources',
      Header: 'Resources',
      accessor: renderResourcesCell,
      disableSortBy: true,
    },
  ],
});

const renderResourcesCell = ({ resources }: Partial<SitecoreContextType>) => {
  const filteredResources = resources?.filter(
    ({ name }) => validResources[name as keyof typeof validResources],
  );
  return (
    <Flex gap={1}>
      {filteredResources?.length ? (
        filteredResources?.map(({ name }) => {
          const { iconUrl, name: displayName } =
            validResources[name as keyof typeof validResources] ?? {};
          return (
            <ImageWithSkeleton
              key={displayName}
              width='7'
              height='7'
              src={iconUrl}
              alt={displayName}
              flexShrink={0}
            />
          );
        })
      ) : (
        <Text variant='subtle'>No resources added.</Text>
      )}
    </Flex>
  );
};
const renderContextIdCell = ({
  sitecoreContextId,
  type,
}: Partial<SitecoreContextType>) => {
  if (type === 'custom') return <Text>{sitecoreContextId}</Text>;
  return (
    <Flex alignItems='center' gap={4}>
      <Text>{sitecoreContextId}</Text>
      <Tag px={2} py={1} fontSize='xs' fontWeight='semibold'>
        Master
      </Tag>
    </Flex>
  );
};
