import {
  mdiAccountMultipleOutline,
  mdiCloudKeyOutline,
  mdiEmailOutline,
  mdiHomeVariantOutline,
  mdiLifebuoy,
  mdiShieldLockOutline,
  mdiSitemapOutline,
  mdiStore,
  mdiWalletOutline,
  mdiWebPlus,
} from '@mdi/js';
import {
  customHostnamesPath,
  invitesPath,
  membersPath,
  mfaPath,
  ssoPath,
  supportAccessPath,
} from 'features/admin/components/admin-paths';
import { useAuth0TokenClaims } from 'features/common/auth/claims';
import { NavigationItemProps } from 'features/common/components/Navigation';
import { useAppAccess } from 'features/common/components/OrganizationAppAccess';
import { PortalIcon } from 'features/common/components/PortalIcon';
import { TokenCustomClaimOrgAccountIdKey } from 'features/domains/gainsight/gainsight';
import { subscriptionsRoute } from 'features/subscriptions/routes';
import { useFeatures } from 'lib/featureFlags/features';
import { useEffect, useMemo, useState } from 'react';
import { contextIdsRoute } from '../../../context-ids/routes';
import { history } from '../../history/history';

const getIcon = (path: string | undefined) => <PortalIcon path={path} />;

const useGetMainMenuItems = () => {
  const { hasOrgOwnerAccess, hasOrgAdminAccess } = useAppAccess();
  const { claims } = useAuth0TokenClaims();
  const features = useFeatures();
  const salesforceId = claims?.[TokenCustomClaimOrgAccountIdKey];
  const [mainMenuItems, setMainMenuItems] = useState<
    NavigationItemProps[] | []
  >([]);

  useEffect(() => {
    const items = [
      {
        label: 'Home',
        href: `/${history.location.search}`,
        active: history.location.pathname === '/',
        valid: true,
        mobileMenuIcon: getIcon(mdiHomeVariantOutline),
      },
      {
        label: 'Admin',
        href: `/admin/members/page/1${history?.location.search}`,
        active: history.location.pathname.startsWith('/admin'),
        valid: hasOrgOwnerAccess || hasOrgAdminAccess,
      },
      {
        label: 'Unified tracking',
        href: `/unified-tracking${history.location.search}`,
        active: history.location.pathname.startsWith('/unified-tracking'),
        valid: features.UNIFIED_TRACKING,
        mobileMenuIcon: getIcon(mdiSitemapOutline),
      },
      {
        label: 'Marketplace',
        href: `/marketplace${history.location.search}`,
        active: history.location.pathname.startsWith('/marketplace'),
        valid: features.MARKETPLACE,
        mobileMenuIcon: getIcon(mdiStore),
      },
      {
        label: 'Subscriptions',
        href: `${subscriptionsRoute}${history?.location.search}`,
        active: history.location.pathname.startsWith(subscriptionsRoute),
        valid: Boolean(
          features.SUBSCRIPTIONS && hasOrgOwnerAccess && salesforceId,
        ),
        mobileMenuIcon: getIcon(mdiWalletOutline),
      },
    ].filter((item) => item.valid);

    setMainMenuItems(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    features.SUBSCRIPTIONS,
    features.MARKETPLACE,
    features.UNIFIED_TRACKING,
    hasOrgAdminAccess,
    hasOrgOwnerAccess,
    salesforceId,
    history.location.pathname, // this is needed so the active can take effect
  ]);

  return { mainMenuItems };
};

const useGetUserManagementMenuItems = () => {
  const features = useFeatures();
  const { hasOrgOwnerAccess, hasOrgAdminAccess } = useAppAccess();

  const [userManagementMenuItems, setUserManagementMenuItems] = useState<
    NavigationItemProps[] | []
  >([]);

  useEffect(() => {
    const items = [
      {
        label: 'Team members',
        href: `/admin${membersPath}${history.location.search}`,
        active: history.location.pathname.startsWith(
          `/admin${membersPath}/page/`,
        ),
        icon: getIcon(mdiAccountMultipleOutline),
        enabled: hasOrgOwnerAccess || hasOrgAdminAccess,
      },
      {
        label: 'Invitations',
        href: `/admin${invitesPath}/page/1${history.location.search}`,
        active: history.location.pathname.startsWith(
          `/admin${invitesPath}/page/`,
        ),
        icon: getIcon(mdiEmailOutline),
        enabled:
          features.INVITATIONS && (hasOrgOwnerAccess || hasOrgAdminAccess),
      },
    ].filter((item) => item.enabled);

    setUserManagementMenuItems(items);
  }, [features, hasOrgAdminAccess, hasOrgOwnerAccess]);

  return { userManagementMenuItems };
};

const useGetorganizationManagementMenuItems = () => {
  const features = useFeatures();
  const { hasOrgOwnerAccess, hasOrgAdminAccess } = useAppAccess();
  const { claims } = useAuth0TokenClaims();
  const [organizationManagementMenuItems, setOrganizationManagementMenuItems] =
    useState<NavigationItemProps[] | []>([]);

  const items = useMemo(
    () =>
      [
        {
          label: 'Multi-factor authentication',
          href: `/admin${mfaPath}${history.location.search}`,
          active: history.location.pathname.startsWith(`/admin${mfaPath}`),
          icon: getIcon(mdiShieldLockOutline),
          enabled: features.MFA && (hasOrgOwnerAccess || hasOrgAdminAccess),
        },
        {
          label: 'Single sign-on (SSO)',
          href: `/admin${ssoPath}${history.location.search}`,
          active: history.location.pathname.startsWith(`/admin${ssoPath}`),
          icon: getIcon(mdiCloudKeyOutline),
          enabled: features.SSO && (hasOrgOwnerAccess || hasOrgAdminAccess),
        },
        {
          label: 'Sitecore Support access',
          href: `/admin${supportAccessPath}${history.location.search}`,
          active: history.location.pathname.startsWith(
            `/admin${supportAccessPath}`,
          ),
          icon: getIcon(mdiLifebuoy),
          enabled:
            features.SUPPORT_ACCESS &&
            (hasOrgOwnerAccess ||
              hasOrgAdminAccess ||
              claims?.sc_support_login),
        },
        {
          label: 'Custom hostnames',
          href: `/admin${customHostnamesPath}${history.location.search}`,
          active: history.location.pathname.startsWith(
            `/admin${customHostnamesPath}`,
          ),
          icon: getIcon(mdiWebPlus),
          enabled:
            features.CUSTOM_HOSTNAMES &&
            (hasOrgOwnerAccess || hasOrgAdminAccess),
        },
      ].filter((item) => item.enabled),
    [features, hasOrgAdminAccess, hasOrgOwnerAccess, claims?.sc_support_login],
  );

  useEffect(() => setOrganizationManagementMenuItems(items), [items]);

  return { organizationManagementMenuItems };
};

const useGetIdManagementMenuItems = () => {
  const features = useFeatures();
  const { hasOrgOwnerAccess, hasOrgAdminAccess } = useAppAccess();

  const [idManagementMenuItems, setIdManagementMenuItems] = useState<
    NavigationItemProps[] | []
  >([]);

  useEffect(() => {
    const items = [
      {
        label: 'Context IDs',
        href: `${contextIdsRoute}${history.location.search}`,
        active: history.location.pathname.startsWith(contextIdsRoute),
        icon: getIcon(mdiSitemapOutline),
        enabled:
          features.CONTEXT_MAPPING && (hasOrgOwnerAccess || hasOrgAdminAccess),
      },
    ].filter((item) => item.enabled);

    setIdManagementMenuItems(items);
  }, [features, hasOrgAdminAccess, hasOrgOwnerAccess]);

  return { idManagementMenuItems };
};

export {
  useGetIdManagementMenuItems,
  useGetMainMenuItems,
  useGetorganizationManagementMenuItems,
  useGetUserManagementMenuItems,
};
