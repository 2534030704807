import { ApmRoute } from '@elastic/apm-rum-react';
import { PageNotFound } from 'features/main/components/CustomErrorPages';
import { Switch } from 'react-router-dom';
import UnifiedTracking from './unified-tracking/UnifiedTracking';

const UnifiedTrackingContainer = () => {
  return (
    <Switch>
      <ApmRoute exact path='/unified-tracking' component={UnifiedTracking} />
      <ApmRoute component={PageNotFound} />
    </Switch>
  );
};

export default UnifiedTrackingContainer;
