import {
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
  ButtonGroup,
  Button,
} from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { CreateEditContextIdDrawerBody } from './CreateEditContextIdDrawerBody';
import {
  contextIdsRoute,
  getContextIdsDetailsRoute,
  getContextIdsEditRoute,
} from 'features/context-ids/routes';
import { useAddEditContextId } from 'features/context-ids/hooks/useAddEditContextId';

export const CreateEditContextIdDrawer = () => {
  const history = useHistory();
  const { sitecoreContextId } = useParams<{ sitecoreContextId: string }>();
  const isInEditMode = Boolean(useRouteMatch(getContextIdsEditRoute()));
  const { isOpen, onClose, onOpen } = useDisclosure();

  const onCloseDrawer = useCallback(
    (id?: string) => {
      const contextId = id || sitecoreContextId || '';
      history.push({
        pathname: contextId
          ? getContextIdsDetailsRoute(contextId)
          : contextIdsRoute,
        search: history.location.search,
      });
      onClose();
    },
    [history, onClose, sitecoreContextId],
  );

  useEffect(() => {
    if (isOpen) return;
    onOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    handleSubmit,
    isLoading,
    values,
    errors,
    handleChange,
    isValid,
    isDisabledInEditMode,
  } = useAddEditContextId({ onCloseDrawer, isInEditMode });

  return (
    <Drawer onClose={onCloseDrawer} isOpen={isOpen} size='lg'>
      <form
        onSubmit={handleSubmit}
        data-testid='add-edit-contextid-drawer-form'
      >
        <DrawerOverlay />
        <DrawerContent
          data-testid='add-edit-contextid-drawer-content'
          data-behavior-analytics-feature='Add edit context id drawer'
        >
          <DrawerHeader display='flex' alignItems='center'>
            {`${isInEditMode ? 'Edit context ID' : 'Add context ID'}`}
          </DrawerHeader>
          <CreateEditContextIdDrawerBody
            {...{
              errors,
              values,
              handleChange,
              isInEditMode,
            }}
          />
          <DrawerFooter justifyContent='flex-end'>
            <ButtonGroup>
              <Button
                data-testid='add-edit-contextid-drawer-cancel-btn'
                type='button'
                variant='ghost'
                onClick={() => onCloseDrawer()}
                isDisabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                type='submit'
                variant='solid'
                data-testid='add-edit-contextid-drawer-save-btn'
                isLoading={isLoading}
                isDisabled={!isValid || isDisabledInEditMode}
              >
                Save
              </Button>
            </ButtonGroup>
          </DrawerFooter>
        </DrawerContent>
      </form>
    </Drawer>
  );
};
