import { contextIdConfig } from '../config';
import { useAuthQuery } from '../../common/apiUtils/queryHelper';
import { SitecoreContextType } from '../types/types';

const { url, queryKey } = contextIdConfig.get_contextid;

export const useGetSitecoreContextId = ({
  sitecoreContextId,
  enabled = true,
}: {
  sitecoreContextId: string;
  enabled?: boolean;
}) => {
  const isEnabled = enabled && !!sitecoreContextId;

  return useAuthQuery<SitecoreContextType>(
    queryKey(sitecoreContextId),
    async (axiosInstance) => {
      const response = await axiosInstance?.get(url(sitecoreContextId));
      return response?.data;
    },
    {
      enabled: isEnabled,
    },
  );
};
